import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Form, Input, Select, notification } from 'antd'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import HeroImage from '../../../../components/HeroImage'
import Button from '../../../../components/Button'
import ImagesMasonry from '../../../../components/ImagesMasonry'

import { ukrainianPhoneNumberPattern } from '../../../../utils/constants'

import './NewYear.scss'

import NewYearHeroImage from '../../../../static/images/special-events/new-year/ny25.jpg'
import NewYearHeroImageMob from '../../../../static/images/special-events/new-year/ny25_mob.jpg'

import config from './config'
import useAbstractionMediaQueries from '../../../../hooks/useAbstractionMediaQueries'

const { Option } = Select

const NewYear = () => {
  const { isTablet } = useAbstractionMediaQueries()
  const sendDataToGTM = useGTMDispatch()

  const { t, i18n } = useTranslation()
  const [isModalOpen, setModalOpenState] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [submitForm] = Form.useForm()

  const HeroImageTitle = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ color: 'white' }}>
          {t('Home.Navigation.NewYearHeroTitleFirst')}
        </span>
      </div>
    )
  }

  const openModal = () => {
    setModalOpenState(true)
  }

  const closeModal = () => {
    submitForm.resetFields()
    setModalOpenState(false)
  }

  const onSubmit = values => {
    const phoneFinal = `38${values.phone}`

    setLoading(true)

    return fetch('https://api.shelest.ua/etc/new-year', {
      body: JSON.stringify({
        ...values,
        phone: phoneFinal,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(() => {
        setLoading(false)
        submitForm.resetFields()
        closeModal()
        sendDataToGTM({
          event: 'FormSentNewYear',
          eventType: 'FormSent',
        })
        notification.success({
          message: t('Notification.Description.InvestRequestSuccess'),
        })
      })
      .catch(err => {
        console.log('err: ', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const bgImage = !isTablet ? NewYearHeroImage : NewYearHeroImageMob

  const langToText = {
    ukr: (
      <>
        <div className="new-year-agenda-wrapper">
          <p>
            У цей надзвичайно важкий час, коли ворог щодня намагається похитнути нашу віру в майбутнє, важливо попри все рухатися вперед. Ми продовжуємо розвивати в собі та наших гостях звичку радіти життю та дозволяти собі перепочити. І тому хочемо зібратися разом у новорічну ніч, щоб знову повірити в диво. Готуйте вечірні образи — святкуємо в стилі Cirque du Soleil!                                
Декорації, жива музика та атмосфера легендарного цирку — на вас чекає насичена програма. Забудьте на 2 дні про турботи, вдягніть найяскравіший наряд та проведіть час із рідними в SHELEST. 
          </p>
          <p>
            Детальніше про програму: 
            <ul>
            <li>
- обіцяємо без клоунів та дивних конкурсів — на сцені-арені з імпровізованим куполом гостей розважатимуть конферансьє, акробати, ілюзіоністи та інші майстри циркового мистецтва;</li><li>
- вечеря від шефа та магічне перетворення порожніх келихів на повні;</li><li>
- виступ наживо Zakohani Band, під який акробатичні трюки зможуть виконати наші гості;</li><li>
- Санта, аніматори та окремі розваги для дітей;</li><li>
- похмільний бранч, келих ігристого та вуличні гуляння 1 січня.
</li>
          </ul>
          </p>
          
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 20,
            paddingTop: 20,
          }}
        >
          
          <Button
            classNames="btn-mid-pad small-btn btn-green h-40"
            onClick={openModal}
            buttonId="new_year_button"
          >
            Хочу святкувати
          </Button>
        </div>
      </>
    ),
    en: (
      <>
        <div className="new-year-agenda-wrapper">
          <p>
            In this extremely difficult time, when the enemy tries to shake our faith in the future every day, it is important to move forward despite everything. We continue to develop in ourselves and our guests the habit of enjoying life and allowing ourselves to rest. And that is why we want to get together on New Year's Eve to believe in a miracle again. Prepare your evening looks - let's celebrate in the style of Cirque du Soleil!
            Decorations, live music and the atmosphere of the legendary circus - a rich program awaits you. Forget about worries for 2 days, put on the brightest outfit and spend time with your loved ones at SHELEST.
            </p>
            <p>
            More details about the program:
            <ul>
            <li>
            - we promise no clowns and strange contests - on the stage-arena with an improvised dome, guests will be entertained by entertainers, acrobats, illusionists and other masters of circus art;</li><li>
            - dinner from the chef and the magical transformation of empty glasses into full ones;</li><li>
            - live performance by Zakohani Band, to which our guests will be able to perform acrobatic tricks;</li><li>
            - Santa, animators and separate entertainment for children;</li><li>
            - a hangover brunch, a glass of sparkling wine and street festivities on January 1.
            </li>
            </ul>
            </p>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 20,
            paddingTop: 20,
          }}
        >
          
          <Button
            classNames="btn-mid-pad small-btn btn-green h-40"
            onClick={openModal}
            buttonId="new_year_button"
          >
            I want to celebrate
          </Button>
        </div>
      </>
    ),
  }

  return (
    <div className="new-year-wrapper">
      <HeroImage
        backgroundImage={bgImage}
        // heroText={<HeroImageTitle />}
        wrapperStyle={{
          height: 600,
        }}
      />
      <div className="new-year-info">
        {/* <div className="new-year-plans">
          <div className="new-year-plans-list-wrapper">
            <h3 className="list-title">Плани на Новий Рік</h3>
            <ul className="list">
              <li className="list-item">Знайти локацію</li>
              <li className="list-item">Придумати тему вечірки</li>
              <li className="list-item">Підготувати меню</li>
              <li className="list-item">Скласти плейлист</li>
              <li className="list-item">Підібрати фільми</li>
              <li className="list-item">Вигадати, як розважити дітей</li>
              <li className="list-item">Конкурси?</li>
              <li className="list-item">Купити продукти</li>
              <li className="list-item">Приготувати святкову вечерю</li>
            </ul>
          </div>
          <div className="new-year-plans-list-wrapper success">
            <h3 className="list-title">Плани на Новий Рік</h3>
            <ul className="list">
              <li className="list-item success">
                <span>
                  забронювати будиночок чи номер в SHELEST на новорічну ніч і
                  робити нічого
                </span>
              </li>
            </ul>
          </div>
        </div> */}
        {langToText[i18n.language]}
        <div style={{ marginTop: 20 }}>
          <ImagesMasonry masonryConfig={config.masonryConfig} />
        </div>
      </div>
      <Modal
        title={t('NewYear.Form.Title')}
        visible={isModalOpen}
        onOk={() => submitForm.submit()}
        okText={t('NewYear.Form.OK')}
        cancelText={t('NewYear.Form.Cancel')}
        onCancel={closeModal}
        okButtonProps={{ loading: isLoading }}
      >
        <Form onFinish={onSubmit} form={submitForm}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
            ]}
          >
            <Input placeholder={t('InputPlaceholders.FullNameShort')} />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                pattern: ukrainianPhoneNumberPattern,
                message: t('Validation.WrongPhoneFormat'),
              },
            ]}
          >
            <Input
              maxLength={10}
              addonBefore="+38"
              placeholder={t('InputPlaceholders.PhoneNumber')}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                type: 'email',
                message: t('Validation.InvalidEmailFormat'),
              },
            ]}
          >
            <Input placeholder={t('InputPlaceholders.Email')} />
          </Form.Item>
          <Form.Item
            name="adults"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                pattern: /^\d+$/,
                message: t('Validation.OnlyNumbers'),
              },
            ]}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Input
                maxLength={3}
                placeholder={t('InputPlaceholders.Adults')}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="children"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                pattern: /^\d+$/,
                message: t('Validation.OnlyNumbers'),
              },
            ]}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Input
                maxLength={3}
                placeholder={t('InputPlaceholders.Children')}
              />
            </div>
          </Form.Item>
          <Form.Item
            label={t('InputPlaceholders.WantToLiveIn')}
            name="appartments"
            rules={[{ required: true, message: t('Validation.Required') }]}
          >
            <Select
              placeholder={t('InputPlaceholders.ChoosePlace.Placeholder')}
            >
              <Option value="готелі">
                {t('InputPlaceholders.ChoosePlace.Hotel')}
              </Option>
              <Option value="будиночку">
                {t('InputPlaceholders.ChoosePlace.House')}
              </Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default NewYear
