import React from 'react'

import NewYear from './events/NewYear'

const SpecialEvent = ({ event }) => {
  const events = {
    new_year: <NewYear />,
  }

  return events[event]
}

export default SpecialEvent
